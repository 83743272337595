.ui-table {
  tbody {
    tr {
      td {
        &.is-button {
          &.is-direct-booking {
            button {
              margin: unset;
            }
          }
        }
      }
    }
  }
}
