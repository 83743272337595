.holiday-details-images {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
  width: fit-content;

  .holiday-details-image {
    display: block;
    height: 110px;
    width: 110px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    &:first-child {
      height: 230px;
      width: 230px;
      grid-row-end: span 2;
      grid-column-end: span 2;
    }

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      transition: 0.2s all;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.holiday-details-text {
  margin-top: 28px;
  max-width: 810px;

  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
    line-height: 1.4em;

    a {
      color: #e41f13;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 2px;
    line-height: 1.4em;
  }

  #btnText {
    display: none !important;
  }

  #testNoShow {
    display: block !important;
  }

  ul {
    padding-left: 20px;
    list-style-type: disc;
    margin-bottom: 24px;

    li {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 8px;
      line-height: 1.4em;

      a {
        color: #e41f13;
      }
    }
  }
}
