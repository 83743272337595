@import "../../../assets/styles/vars.scss";

.assign-agent-wrap {
  max-height: 375px;
  overflow-y: auto;
}

.assign-agent-table {
  width: 100%;

  tbody {
    tr {
      &:nth-child(odd) {
        background: $inputBackground;
      }

      td {
        padding: 4px 4px 4px 16px !important;
        line-height: 1em;

        &:last-child {
          button {
            margin-left: auto;
          }
        }
      }
    }
  }
}
