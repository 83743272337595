@import "../../../assets/styles/vars.scss";

.ui-input-wrapper {
	position: relative;

	&.has-error {
		.ui-input-block {
			.ui-input-text {
				border: 1px solid red;
			}
		}

		small {
			color: red;
		}
	}

	&.has-symbol {
		.ui-input-block {
			.ui-input-text {
				padding: 10px 4px 10px 36px;
			}
		}
	}

	&.only-active-on-hover {
		.ui-input-block {
			.ui-input-text {
				color: $label !important;
				background: $inputBackground !important;
				border: 1px solid $inputBorder !important;

				&:hover,
				&:focus,
				&:active {
					background: $inputBackgroundActive !important;
					border: 1px solid $inputBorderActive !important;
				}
			}
		}
	}

	.ui-input-block {
		position: relative;

		.ui-input-icon {
			position: absolute;
			top: 50%;
			left: 14px;
			transform: translateY(-50%);

			svg {
				display: block;
				height: 14px;
				width: auto;
				pointer-events: none;

				path {
					fill: $inputPlaceholder;
				}
			}
		}

		.ui-input-symbol {
			position: absolute;
			top: 1px;
			left: 1px;
			bottom: 1px;
			width: 25px;
			background: $inputBorder;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $inputPlaceholder;
			font-size: 12px;
			border-radius: 4px 0 0 4px;
		}

		.ui-input-upper-placeholder {
			position: absolute;
			top: 50%;
			left: 20px;
			transform: translateY(-50%);
			font-size: 14px;
			font-weight: 500;
			color: $inputPlaceholder;
			transition: 0.25s all;
			opacity: 0;
			padding: 3px 6px;
			pointer-events: none;

			&.active {
				top: 0px;
				left: 14px;
				opacity: 1;
				font-size: 12px;
				background: #fff;
				border-radius: 6px;
			}
		}

		.ui-input-text {
			font-size: 15px;
			background: $inputBackground;
			border: 1px solid $inputBorder;
			transition: 0.2s all;
			border-radius: 5px;
			padding: 10px 18px;
			width: 100%;
			outline: none !important;
			color: $label;
			font-family: "Rubik", sans-serif;

			&:read-only {
				color: $label !important;
				background: $inputBackground !important;
				border: 1px solid $inputBorder !important;
			}

			&.has-icon {
				padding: 7px 18px 7px 38px;
			}

			&.no-resize {
				resize: none;
			}

			&::placeholder {
				font-size: 14px;
				font-weight: 400;
				color: $inputPlaceholder;
			}

			&:focus,
			&:active,
			&:not(:placeholder-shown) {
				background: $inputBackgroundActive;
				border: 1px solid $inputBorderActive;
			}
		}
	}

	small {
		display: block;
		font-size: 12px;
		font-weight: 400;
		line-height: 1em;
		margin: 5px 0 0;
		color: $inputPlaceholder;
	}
}
