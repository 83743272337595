@import "../../assets/styles/vars.scss";

#login-page {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $ghdRed;

  .login-window {
    background: #fff;
    padding: 50px 45px;
    min-width: 200px;
    border-radius: 15px;

    img {
      max-width: 345px;
      margin: 0 0 14px;
    }

    p {
      max-width: 325px;
      font-size: 15px;
      line-height: 1.45em;
      text-align: center;
      margin: 0 auto 30px;
    }

    .login-form {
      display: grid;
      grid-gap: 10px;
      max-width: 310px;
      margin: 0 auto;
    }

    .login-error {
      max-width: 250px;
      font-size: 14px;
      line-height: 1.45em;
      text-align: center;
      margin: 5px auto;
      color: $ghdRed;
    }
  }
}

@media (max-width: 480px) {
  #login-page {
    padding: 20px;

    .login-window {
      padding: 40px 35px;

      img {
        max-width: 100%;
        margin: 0 auto 14px;
      }
    }
  }
}
