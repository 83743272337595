.container {
	display: grid;
	grid-gap: 50px;
	grid-template-columns: 570px 450px;

	.items {
		.title {
			font-size: 14px;
			margin-bottom: 12px;
			opacity: 0.4;
		}

		.item {
			position: relative;
			width: 100%;
			background: #fff;
			border: 1px solid #ebebeb;
			border-radius: 8px;
			overflow: hidden;
			box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;
			padding: 10px 14px;

			&:not(:first-of-type) {
				margin-top: 8px;
			}

			.type {
				font-size: 13px;
				text-transform: capitalize;
			}

			.email {
				font-size: 15px;
				margin-top: 2px;
			}

			.price {
				font-weight: 600;
				margin-top: 6px;
			}

			.badges {
				display: flex;
				gap: 5px;
				margin-top: 10px;
			}
		}
	}
}
