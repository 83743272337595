@import "../../assets/styles/vars.scss";

.generate-quote-wrapper {
  display: flex;
  margin-top: 60px;

  .generate-quote-menu {
    position: relative;
    margin-right: 75px;
    padding-right: 55px;
    height: fit-content;

    .quote-menu-stage-bar {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 5px;
      border-radius: 5px;
      background: rgba(228, 31, 19, 0.25);

      .quote-menu-stage-bar-inner {
        width: 5px;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(228, 31, 19, 1);
        transition: 0.2s all;

        &.stage-0 {
          height: 6%;
        }

        &.stage-1 {
          height: 25%;
        }

        &.stage-2 {
          height: 44%;
        }

        &.stage-3 {
          height: 62%;
        }

        &.stage-4 {
          height: 82%;
        }

        &.stage-5,
        &.stage-6 {
          height: 100%;
        }
      }
    }

    .quote-menu-stage {
      font-size: 15px;
      opacity: 0.65;
      display: flex;
      align-items: center;

      &.is-open {
        opacity: 1;
      }

      &:not(:last-of-type) {
        margin-bottom: 32px;
      }

      .quote-menu-package-count {
        height: 15px;
        width: 15px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        background: #e41f13;

        span {
          display: block;
          text-align: center;
          font-size: 10px;
          color: #fff;
          margin-left: -1.25px;
        }
      }
    }
  }

  .generate-quote-functionality {
    width: 600px;

    .generate-quote-header {
      margin-bottom: 28px;

      .generate-quote-title {
        font-size: 22px;
        font-weight: 400;

        &.quote-sent {
          svg {
            height: 14px;
            width: auto;
            margin-left: 5px;
            margin-top: -1px;

            path {
              fill: $positiveFG;
            }
          }
        }
      }

      .generate-quote-subtitle {
        font-size: 16px;
        font-weight: 300;
        color: #8c8b97;
        margin: 10px 0 0;
      }
    }

    .quote-action-buttons {
      display: flex;
      align-items: center;
      margin-top: 65px;

      &.space-between,
      &.is-double {
        justify-content: space-between;
      }

      &.is-triple {
        justify-content: space-between;

        .ui-button {
          margin: 0 !important;

          &:nth-child(2) {
            margin: 0 10px 0 auto !important;
          }
        }
      }

      &:not(.space-between) {
        &:not(.is-triple) {
          &:not(.is-double) {
            .ui-button {
              margin-left: auto;
            }
          }
        }
      }
    }

    .holiday-search {
      position: relative;
      width: 370px;

      .ui-input-wrapper {
        .ui-input-block {
          .ui-input-text {
            &.has-icon {
              padding: 11px 18px 11px 38px;
            }
          }
        }
      }

      .quote-search-empty {
        display: block;
        font-size: 14px;
        margin-top: 10px;
        color: $headingColorLight;

        &:last-of-type {
          display: none;
        }
      }

      .loading-spinner {
        margin-top: 10px;
      }

      .quote-search-dropdown {
        position: absolute;
        z-index: 999;
        width: 100%;
        top: 100%;
        left: 0;
        right: 0;
        border-radius: 0 0 5px 5px;
        background: #fff;
        max-height: 500px;
        overflow-y: auto;

        .quote-search-result-group {
          border-left: 1px solid $inputBorder;
          border-right: 1px solid $inputBorder;

          &:last-of-type {
            border-bottom: 1px solid $inputBorder;
          }
        }

        small {
          display: block;
          font-size: 14px;
          padding: 10px 18px;
          color: #878787;
        }

        .quote-search-result-row {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: $label;
          padding: 10px 18px;
          cursor: pointer;
          background: #fff;
          transition: 0.2s all;
          
          p {
            line-height: 1.24em;
          }

          &.is-active,
          &:hover {
            color: #fff;
            background: $primary;
          }

          .ui-badge {
            margin-right: 10px;
          }
        }
      }
    }

    .quote-option-stack {
      margin-top: 40px;
    }
  }
}

.quote-option-row {
  height: 54px;
  width: 100%;
  max-width: 675px;
  padding: 0 16px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr auto;
  align-items: center;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  .quote-option-row-title {
    display: flex;
    align-items: center;
    font-size: 15px;

    .ui-badge {
      margin-right: 10px;
    }

    &.quote-option-pdf-title {
      svg {
        height: 9px;
        width: auto;
        margin-left: 8px;
        margin-top: -1px;

        path {
          fill: $positiveFG;
        }
      }
    }
  }

  .quote-options-end-actions {
    display: flex;
    align-items: center;
    
    .quote-option-end-action {
      display: flex;
      align-self: center;
      height: 26px;
      width: 26px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    
      &:not(:last-of-type) {
        margin-right: 2px;
      }
    
      &:hover {
        background: #ebebeb;
      }
    }
  }

  .quote-option-row-target-delete {
    display: flex;
    align-self: center;
    height: 26px;
    width: 26px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 2px;
    }

    &:hover {
      background: #ebebeb;
    }
  }
}

.quote-option-row-open {
  height: fit-content;
  width: 100%;
  max-width: 675px;
  padding: 0 16px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  .quote-option-row-open-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0;
    cursor: pointer;

    .quote-option-row-title {
      display: flex;
      align-items: center;
      margin-bottom: -2px;
      font-size: 15px;

      .ui-badge {
        margin-right: 10px;
      }
    }

    .quote-option-row-toggle-open {
      margin-top: -2px;
    }
  }

  .quote-option-row-open-body {
    display: none;

    &.is-open {
      display: block;
      padding: 5px 0 20px;
    }

    .loading-spinner {
      p {
        color: #16171a;
        font-size: 14px;
      }

      .loading-svg-wrap {
        height: 10px;
        width: 10px;
      }
    }

    .quote-package-option-row {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: 175px 1fr;
      align-items: center;
      max-width: 475px;

      &.with-bottom {
        margin-bottom: 12px;
      }

      .quote-package-option-row-combined {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
      }

      .quote-package-option-row-label {
        font-size: 15px;
        color: #585858;
      }
    }
  }
}

.generate-quote-save-to-client {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;

  .ui-input-wrapper {
    &:last-of-type {
      grid-column-end: span 2;
    }
  }
}

.quote-available-packages {
  display: none;
  margin-top: 20px;

  &.is-visible {
    display: block;
  }

  .holiday-price-row {
    position: relative;
    width: 100%;
    max-width: 705px;
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;

    &.is-highlighted {
      border: 1px solid #e41f13;
    }

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    .quote-choosing-nights {
      position: absolute;
      z-index: 100;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(10px);
      flex-wrap: wrap;

      .ui-input-wrapper {
        margin-right: 10px;
      }

      .overrun-error {
        position: absolute;
        bottom: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 100%;
        text-align: center;
        color: #16171a;
        font-size: 13.5px;

        svg {
          margin-right: 8px;
          margin-top: -2.3px;
        }
      }
    }

    .price-row-name {
      display: flex;
      align-items: center;
      padding: 12px 16px 10px 26px;

      .ui-badge {
        margin-right: 12px;
      }
      
      p {
        font-size: 15px;
        font-weight: 400;
      }

      &.with-icon {
        svg {
          margin-right: 10px;
          margin-top: -3px;
        }
      }

      .price-option-action {
        height: 26px;
        width: 26px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: auto;

        &:hover {
          background: #ebebeb;
        }
      }
    }

    .price-row-details {
      height: 60px;
      display: flex;
      align-items: center;
      border-top: 1px solid #ebebeb;

      &.no-flex {
        height: auto;
        padding: 12px 26px;
        flex-direction: column;
        align-items: unset;
      }
    }

    .no-price-window-notice {
      font-size: 15px;
      line-height: 1.2em;
      max-width: 500px;
      margin-bottom: 12px;
    }

    .no-price-window-options {
      display: grid;
      grid-gap: 10px;

      .no-price-window-option-row {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: 125px 1fr;
        align-items: center;

        p {
          font-size: 15px;
        }
      }
    }

    .no-price-window-add {
      display: flex;
      justify-content: flex-end;
      margin-top: 2px;
    }

    .price-option-dates {
      display: flex;
      align-items: center;
      padding: 0 0 0 26px;
      min-width: 295px;

      p {
        font-size: 16px;
        font-weight: 500;
      }

      svg {
        margin: 0 14px;
      }
    }

    .price-option-range {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 0 0 26px;
      min-width: 155px;
      border-left: 1px solid #ebebeb;
    }

    .price-option-period {
      padding: 0 0 0 26px;
    }

    .price-option-actions {
      display: flex;
      align-items: center;
      padding: 0 16px 0 0;
      margin-left: auto;

      .price-option-action {
        height: 26px;
        width: 26px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 2px;
        }

        &:hover {
          background: #ebebeb;
        }
      }
    }
  }
}

.configure-package-options {
  margin-top: 20px;
}

.generate-quote-total {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 0 0;

  span {
    font-size: 14px;
    font-weight: 400;
    color: #8c8b97;
    margin: 0 0 6px;
  }

  p {
    font-size: 25px;
    font-weight: 500;
  }
}

.generate-quote-client-details {
  display: grid;
  grid-gap: 14px;
  grid-template-columns: 1fr 1fr;

  .ui-input-wrapper {
    &:last-of-type {
      grid-column-end: span 2;
    }
  }
}

.finalised-quote-settings {
  .quote-introduction-edit {
    position: relative;
    margin-bottom: 24px;

    p {
      position: absolute;
      z-index: 5;
      top: 11px;
      opacity: 0.45;
      font-size: 15px;
      color: #303237;
      text-indent: 19px;
      margin-bottom: 5px;
  
      strong {
        font-weight: 600;
      }
    }
  
    .ui-input-wrapper {
      textarea {
        position: relative;

        &.ui-input-text {
          padding: 28px 18px 10px;
        }
      }
      
      &:after {
        content: '';
        position: absolute;
        z-index: 3;
        top: 1px;
        left: 1px;
        right: 1px;
        height: 30px;
        background: #fff;
        border-radius: 5px;
      }
    }
  }
}

.quote-edit-header {
  margin-bottom: 10px;

  .quote-edit-header-title {
    font-size: 15px;
    font-weight: 400;
    color: #121212;
    margin: 0;
  }

  .quote-edit-header-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: $headingColor;
    margin: 4px 0 0;
    opacity: 0.65;
  }
}

.year-offset-margin {
  margin-top: 24px;
}

.quote-global-replace {
  .quote-global-replace-fields {
    display: flex;
    align-items: center;

    .ui-input-wrapper {
      flex: 1 1;
    }

    svg {
      margin: 0 14px;
    }
  }
}