.container {
	position: relative;
	background: #fff;
	border: 1px solid #ebebeb;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;
	margin-top: 15px;
	max-width: 1150px;
	padding: 22px 20px;

	h1 {
		font-size: 17px;
		font-weight: 500;
		margin-bottom: 15px;
	}

	.grid {
		display: grid;
		grid-gap: 45px;
		grid-template-columns: 2fr 3fr;

		.action {
			margin-top: 16px;
			display: flex;
			justify-content: flex-end;
		}

		.seatChooser {
			position: relative;
			background: #f7f7f7;
			border-radius: 8px;
			min-height: 450px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #ebebeb;
			overflow: hidden;

			.flightChooser {
				position: absolute;
				z-index: 21;
				top: 16px;
				width: 225px;
			}

			.loading {
				position: absolute;
				z-index: 11;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				right: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #f7f7f7;

				.icon {
					position: relative;
					height: 12px;
					width: 12px;
					margin-left: 8px;
					transform: rotate(10deg);
					animation-name: loadingSpinner;
					animation-duration: 0.75s;
					animation-iteration-count: infinite;
					transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

					svg {
						position: absolute;
						top: 0;
						right: 0;
						height: 78%;
					}
				}

				p {
					font-size: 16px;
					font-weight: 400;
					color: #585858;
				}

				@keyframes loadingSpinner {
					from {
						transform: rotate(0deg);
					}

					to {
						transform: rotate(360deg);
					}
				}
			}

			.chooserWarning {
				position: absolute;
				z-index: 10;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				right: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				backdrop-filter: blur(4px);
				background-color: rgba(255, 255, 255, 0.65);

				p {
					font-size: 15px;
					line-height: 1.3em;
					width: 225px;
					text-align: center;
				}
			}
		}
	}
}
