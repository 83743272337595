.holiday-courses-container {
    small {
    display: block;
    font-size: 14px;
    color: #878787;
    margin: 20px 0 10px;
  }
  
  .holiday-linked-course {
      width: 100%;
      max-width: 450px;
      background: #fff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.2em;
      padding: 14px 20px 14px 20px;
      display: flex;
      align-items: center;
  
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
  }
}