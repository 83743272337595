.content-tabs {
	display: flex;
	align-items: center;
	padding: 5px;
	border-radius: 4px;
	background: #f7f7f7;
	width: fit-content;
	gap: 5px;
	margin-bottom: 8px;

	&.isDisabled {
		pointer-events: none;
		opacity: 0.45;
	}

	.content-toggle-tab {
		font-size: 13px;
		padding: 4px 8px;
		border-radius: 4px;
		background: rgba(0, 0, 0, 0.05);
		opacity: 0.45;
		cursor: pointer;
		user-select: none;

		&.isActive,
		&:hover {
			opacity: 1;
			color: #fff;
			background: #e41f13;
		}
	}
}

.currently-editing {
	margin-bottom: 20px;

	p {
		font-size: 14px;
		font-weight: 400;

		strong {
			font-weight: 500;
		}
	}
}

.editor-wrapper {
	max-width: 1200px;
}
