@import "../../../assets/styles/vars.scss";

.ui-input-wrapper {
  &.is-datepicker {
    .ui-input-block {
      .ui-input-icon {
        left: 16px;

        svg {
          height: 14px;
        }
      }
    }
  }

  &.has-value {
    .ui-input-block {
      .flatpickr-input {
        background: $inputBackgroundActive;
        border: 1px solid $inputBorderActive;
      }
    }
  }

  &.read-only {
    .ui-input-block {
      .flatpickr-input {
        cursor: default;
        color: $label !important;
        background: $inputBackground !important;
        border: 1px solid $inputBorder !important;
      }
    }
  }

  &.only-active-on-hover {
    .ui-input-block {
      .flatpickr-input {
        color: $label !important;
        background: $inputBackground !important;
        border: 1px solid $inputBorder !important;

        &:hover,
        &:focus,
        &:active {
          background: $inputBackgroundActive !important;
          border: 1px solid $inputBorderActive !important;
        }
      }
    }
  }

  .ui-input-block {
    .ui-input-upper-placeholder {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      font-size: 14px;
      font-weight: 500;
      color: $inputPlaceholder;
      transition: 0.25s all;
      opacity: 0;
      padding: 3px 6px;

      &.active {
        top: 0px;
        left: 14px;
        opacity: 1;
        font-size: 12px;
        background: #fff;
      }
    }

    .flatpickr-input {
      font-size: 15px;
      background: $inputBackground;
      border: 1px solid $inputBorder;
      transition: 0.2s all;
      border-radius: 5px;
      padding: 10px 18px 10px 37px;
      width: 100%;
      outline: none !important;
      color: $label;
      font-family: "Rubik", sans-serif;

      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: $inputPlaceholder;
      }

      &:focus,
      &:active,
      &:not(:placeholder-shown) {
        background: $inputBackgroundActive;
        border: 1px solid $inputBorderActive;
      }
    }
  }
}

.flatpickr-calendar {
  &.arrowTop {
    &:after {
      border-bottom-color: $primary !important;
    }
  }

  &.arrowBottom {
    &:after {
      border-top-color: #fff !important;
    }
  }

  .flatpickr-months {
    .flatpickr-month {
      background: $primary;
    }
  }

  .flatpickr-current-month {
    .flatpickr-monthDropdown-months {
      background: $primary;
      transition: 0.2s all;

      &:hover {
        background: darken($primary, 5%);
      }
    }
  }

  .flatpickr-weekdays {
    background: $primary;

    .flatpickr-weekday {
      color: #fff;
      background: $primary;
    }
  }

  .flatpickr-day {
    &.selected {
      background: $primary;
      border-color: $primary;
      transition: 0.2s all;

      &:hover {
        background: darken($primary, 5%);
        border-color: darken($primary, 5%);
      }
    }
  }
}
