@import "../../../assets/styles/vars.scss";

#app-navigation {
  position: relative;
  z-index: 10;
  flex: 0 0 auto;
  min-width: 270px;
  user-select: none;

  .nav-user {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px 20px 0 35px;
    margin: auto 0 10px;
    transition: 0.2s all;
    border-top: 1px solid #eff2f5;

    &:hover {
      color: $ghdRed;

      .nav-user-signout {
        svg {
          path {
            fill: $ghdRed;
          }
        }
      }

      .nav-user-name {
        color: $ghdRed;
      }
    }

    .nav-profile-image {
      position: relative;
      height: 42px;
      width: 42px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 10px;
      background: $ghdRed;

      img {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .profile-initials {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        color: #fff;
        line-height: 1em;
        font-weight: 500;
      }
    }

    .nav-user-name {
      font-weight: 700;
      font-size: 15px;
      line-height: 1.2em;
      color: #0a0a0a;
      transition: 0.2s all;

      small {
        display: block;
        cursor: pointer;
        font-size: 13px;
        line-height: 1.2em;
        opacity: 0.5;
      }
    }

    .nav-user-signout {
      margin-left: auto;
      padding-left: 10px;

      svg {
        width: 20px;

        path {
          fill: #0a0a0a;
          transition: 0.2s all;
        }
      }
    }
  }

  #nav-desktop {
    height: 100%;
    display: flex;
    max-height: 100vh;
    flex-direction: column;

    .nav-container-upper {
      flex: 1 1;
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 30px;
    }

    .nav-logo {
      position: relative;
      width: 220px;
      margin: 42px 20px 40px 32px;

      img {
        width: 100%;
      }

      .nav-version {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 12px;
        font-weight: 500;
        color: $disabledFG;
        font-style: italic;
      }
    }

    ul {
      margin: 0 20px 0 35px;

      li {
        &:not(:last-child) {
          margin-bottom: 24px;
        }

        &.inactive {
          pointer-events: none;
        }

        .nav-item-icon {
          width: 15px;
          margin-right: 15px;

          svg {
            width: 20px;

            path {
              fill: #0a0a0a;
              transition: 0.2s all;
            }
          }
        }

        p,
        a {
          display: flex;
          align-items: center;
          font-size: 17px;
          color: #0a0a0a;
          font-weight: 400;
          line-height: 1.2em;
          border-radius: 4px;
          transition: 0.2s all;
          width: fit-content;

          &:hover,
          &.active {
            color: $ghdRed;

            .nav-item-icon {
              svg {
                path {
                  fill: $ghdRed;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  #app-navigation {
    #nav-desktop {
      .nav-logo {
        margin: 40px 20px 50px 35px;
      }

      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 18px;
          }

          a,
          p {
            font-size: 17px;
          }
        }
      }
    }
  }
}

.nav-blocks-wrapper {
  padding: 0 32px;
}

.nav-link-block {
  &.with-top-margin {
    margin: 26px 0 0;
  }

  .nav-link-block-title {
    font-size: 13px;
    font-weight: 600;
    color: #9a9a9a;
    margin: 0 0 16px 12px;
  }

  .nav-link-item {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;

    &.is-active,
    &:hover {
      background: #f6f6f6;
    }

    &.is-disabled {
      opacity: 0.35;
      pointer-events: none;
    }

    .nav-link-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17px;
      margin-right: 12px;

      svg {
        width: 100%;
        height: auto;
        max-height: 15px;
        margin: auto;
      }
    }

    span {
      display: block;
      position: relative;
      margin: 0 0 -2px;
      font-size: 15px;
      color: #0a0a0a;
      line-height: 15px;
    }
  }
}

.nav-link-block-seperator {
  display: block;
  border: none;
  width: 100%;
  height: 2px;
  background: #f1f1f1;
  margin: 22px 0;
}
