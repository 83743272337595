.fetch-progress {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 12px;

	small {
		font-size: 13px;
		opacity: 0.65;
	}

	p {
		margin-top: 3px;
		font-size: 15px;
	}
}
