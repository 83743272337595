@import "../../assets/styles/vars.scss";

.reservation-background {
  padding: 20px;
  background: #eff2f5;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .reservation-tile {
    position: relative;
    background: #fff;
    padding: 30px 55px;
    border-radius: 18px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 90%;
      max-width: 475px;
    }

    .reservation-intro {
      margin: 40px 0 30px;
      max-width: 400px;
      text-align: center;

      h1 {
        font-size: 24px;
        font-weight: 600;
        margin: 0 0 16px;
      }

      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.55em;
      }
    }

    .reservation-table {
      margin: 0 0 24px;

      .booking-table {
        tbody {
          tr {
            &:nth-child(even) {
              background: $inputBackground;
            }

            td {
              font-size: 16px;
              line-height: 32px;
              border-bottom: none;
              border-right: 5px solid #fff;

              &:first-child {
                width: 175px;
                line-height: 32px;
                padding-left: 10px;
              }

              &:nth-child(2) {
                width: auto;
                max-width: 345px;
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }
        }
      }
    }

    .reservation-actions {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.reservation-modal {
  button {
    margin-top: 10px;
  }
}
