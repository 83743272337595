@import "../../../assets/styles/vars.scss";

.ui-horizontal-nav {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid $tileBG;
  margin-bottom: 20px;

  &.is-center {
    width: fit-content;
    margin: 0 auto 20px;
    justify-content: center;
  }

  .ui-horizontal-nav-link {
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: $inputPlaceholder;
    transition: 0.2s all;
    cursor: pointer;
    line-height: 1em;

    &:not(:last-child) {
      margin-right: 34px;
    }

    &:hover {
      color: $primary;
    }

    &.is-active {
      color: $primary;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -6px;
        height: 1px;
        background: $primary;
      }
    }

    small {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      background: $primary;
      top: -2px;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      color: #fff;
      font-size: 11px;
      line-height: 5px;
    }
  }
}
