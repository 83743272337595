@import "../../../assets/styles/vars.scss";

.ui-table {
  position: relative;
  width: 100%;

  .table-loading-splash {
    position: absolute;
    z-index: 4005;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 0 0 55px;

    .loading-wrapper {
      display: flex;
      align-items: center;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: $headingColor;
    }

    .svg-loading-wrap {
      position: relative;
      height: 12px;
      width: 12px;
      margin-left: 8px;
      transform: rotate(10deg);
      animation-name: loadingSpinner;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

      svg {
        position: absolute;
        top: 0;
        right: 0;
        height: 78%;
      }
    }

    @keyframes loadingSpinner {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  thead {
    th {
      text-align: left;
      color: $headingColor;
      font-size: 14px;
      line-height: 1em;
      padding-bottom: 8px;
      padding-right: 12px;
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      position: relative;
      text-align: left;

      &:nth-child(odd) {
        background: #f8f8f8;
      }

      &:nth-child(even) {
        border-top: 1px solid #eff2f5;
        border-bottom: 1px solid #eff2f5;
      }

      .no-values-tag {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 5px;
        background: $amberFG;
      }

      td {
        font-size: 15px;
        line-height: 42px;
        white-space: nowrap;
        padding-right: 12px;

        small {
          color: $headingColorLight;

          &.no-value {
            font-size: 14px;
          }
        }

        a {
          color: #000;
          text-decoration: underline;
        }

        &.is-button {
          a,
          button {
            margin: 0 5px 0 auto;
            text-decoration: none;
          }

          a {
            position: relative;
            background: $primary;
            border: none;
            color: #fff;
            cursor: pointer;
            transition: 0.2s all;
            font-family: $primaryFont;
            font-weight: 400;
            border-radius: 4px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            padding: 10px 14px;
            font-size: 12px;
            line-height: 1em;
            width: fit-content;

            &:hover {
              background: darken($primary, 5%);
            }
          }
        }
      }
    }
  }
}
