.screen-tile {
  position: relative;
  background: #fff;
  padding: 30px 55px;
  border-radius: 18px 18px 0 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .back-button {
    position: absolute;
    z-index: 3005;
    left: 20px;
    top: 15px;
  }

  &.fit-width {
    width: fit-content;
    border-radius: 18px;
  }

  &.center {
    margin: auto;
  }
}

@media (max-width: 797px) {
  #itinerary-page {
    .screen-tile {
      width: 100%;
      padding: 25px 16px 20px;

      .back-button {
        top: 5px;
        right: 5px;
        left: unset;
      }
    }
  }
}
