#app-screen-wrapper {
  display: flex;
  position: relative;
  background: #fff;

  #app-screen-height-contain {
    width: 100%;
    height: 100vh;
    overflow: auto;
    position: relative;

    #app-screen-inner {
      display: flex;
      position: relative;
      margin: 20px 20px 0 0;
      padding: 20px 20px 0 20px;
      background: #eff2f5;
      border-radius: 25px 25px 0 0;
      height: calc(100% - 20px);

      #app-screen {
        flex: 1 1 auto;
        background: #ebecee;
        padding: 40px;
        overflow-y: auto;
      }
    }
  }
}
