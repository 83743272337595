@import "../../assets/styles/vars.scss";

.page-filters {
	&.conversion-filters {
		display: flex;
		align-items: center;
		grid-template-columns: unset;
		grid-gap: unset;

		.ui-select-wrapper {
			width: 185px;
			margin-right: 12px;
		}

		.ui-input-wrapper {
			width: 125px;
			margin-right: 12px;
		}

		.ui-button {
			margin-right: 12px;
		}
	}
}

.ui-table {
	&.conversions-table {
		position: relative;
		width: calc(100% + 110px);
		left: -55px;

		&.has-extra-margin {
			margin-top: 40px;
		}

		thead {
			th {
				&:nth-child(1) {
					padding-left: 55px;
					width: 215px;
				}

				&:nth-child(2) {
					width: 185px;
				}

				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6) {
					width: 100px;
				}

				&:nth-child(7) {
					width: auto;
				}
			}
		}

		tbody {
			tr {
				td {
					&:nth-child(1) {
						padding-left: 55px;
						width: 215px;
					}

					&:nth-child(2) {
						width: 185px;
					}

					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5),
					&:nth-child(6) {
						width: 90px;
					}

					&:nth-child(7) {
						width: auto;
					}
				}

				&.totals-row {
					td {
						font-weight: 500;
					}
				}
			}
		}
	}
}

.conversions-table {
	canvas {
		background: white;
		padding: 10px;
		border-radius: 4px;
		width: 100%;
	}
}

.table-loading-splash {
	padding: 0 0 30px 0;

	.loading-wrapper {
		display: flex;
		align-items: center;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		color: $headingColor;
	}

	.svg-loading-wrap {
		position: relative;
		height: 12px;
		width: 12px;
		margin-left: 8px;
		transform: rotate(10deg);
		animation-name: loadingSpinner;
		animation-duration: 0.75s;
		animation-iteration-count: infinite;
		transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

		svg {
			position: absolute;
			top: 0;
			right: 0;
			height: 78%;
		}
	}

	@keyframes loadingSpinner {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}
}
