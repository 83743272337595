@import '../../../../assets/styles/vars.scss';

.generate-quote-chosen-package {
    width: 100%;
    max-width: 705px;
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;
    
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    .package-name {
        display: flex;
        align-items: center;
        padding: 12px 16px 12px 12px;
      
        .ui-badge {
            margin-right: 10px;
        }

        p {
            font-size: 15px;
            font-weight: 400;

            &:last-child {
                font-weight: 500;
                margin-left: auto;
            }

            span {
                display: block;
                margin-top: 4px;
                font-size: 14px;
                color: $headingColorLight;
            }
        }
    }
    
    .resort-global-notes {
        .resort-global-note {
            padding: 15px 26px;
            background: #f4f4f4;
            border-top: 1px solid #ebebeb;
            
            small {
                font-size: 12px;
                color: $headingColor;
            }

            p {
                font-size: 15px;
                line-height: 1.2em;
            }
        }
    }
    
    .package-options {
        display: grid;
        grid-gap: 10px;

        &:not(.is-hidden) {
            padding: 12px 16px 10px 26px;
            
            &:not(:first-child) {
                border-top: 1px solid #ebebeb;
            }
        }

        .package-options-title {
            font-size: 15px;
            font-weight: 500;
            margin: 0 0 3px;
        }

        .package-option-row {
            display: grid;
            grid-gap: 12px;
            grid-template-columns: 175px 1fr;
            align-items: center;
            
            &.quote-pax {
                grid-template-columns: 175px 1fr;
            }

            &.sell-at-price {
                grid-template-columns: 175px 50px 1fr;
            }
            
            &.quote-additional-nights {
                grid-template-columns: 175px 1fr 2fr;

                .ui-checkbox {
                    label {
                        color: #000;
                        font-size: 15px;
                    }
                }

                .ui-input-wrapper {
                    pointer-events: none;
                    user-select: none;
                    opacity: 0.4;
                }

                &.is-checked {
                    .ui-input-wrapper {
                        pointer-events: all;
                        user-select: all;
                        opacity: 1;
                    }
                }
            }

            &.is-course-buggies {
                grid-template-columns: 175px 1fr 2fr;

                .ui-input-wrapper {
                    pointer-events: none;
                    user-select: none;
                    opacity: 0.4;
                }

                &.is-checked {
                    .ui-input-wrapper {
                        pointer-events: all;
                        user-select: all;
                        opacity: 1;
                    }
                }
            }

            .ui-select-placeholder {
                font-size: 14px;
            }

            p {
                font-size: 15px;
            }

            .package-notes {
                font-size: 14px;
                color: #303237;
            }

            .resort-discounts {
                .ui-checkbox {
                    &:not(:last-of-type) {
                        margin-bottom: 8px;
                    }

                    label {
                        white-space: normal;
                        max-width: 100%;
                        line-height: 1.25em;
                    }
                }
            }
        }
    }
}

.generate-quote-adhoc-fields {
    display: grid;
    grid-gap: 10px;
    padding: 14px 26px;
    margin-top: 20px;

    .generate-quote-adhoc-title {
        font-size: 15px;
        font-weight: 500;
        margin: 0 0 3px;
    }

    .generate-quote-adhoc-field {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: 175px 1fr;
        align-items: center;
        min-height: 40px;
        
        &:not(.sell-at-price) {
            &:not(.exchange-rate) {
                .ui-input-wrapper {
                    pointer-events: none;
                    user-select: none;
                    opacity: 0.4;
                }
            }
        }
        
        &.sell-at-price {
            color: #000;
            font-size: 15px;
        }

        &.exchange-rate {
            color: #000;
            font-size: 15px;
        }

        &.is-included {
            .ui-input-wrapper {
                pointer-events: all !important;
                user-select: all !important;
                opacity: 1 !important;
            }
        }

        .ui-checkbox {
            label {
                color: #000;
                font-size: 15px;
            }
        }
    }
}

.generate-quote-totals-table {
    display: grid;
    margin: 22px auto 0;
    max-width: 385px;

    .generate-quote-totals-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px;
        min-height: 24px;

        &:nth-child(even) {
            background: #f8f8f8;
        }

        p {
            font-size: 14px;

            &:last-of-type {
                font-size: 16px;
                font-weight: 500;
            }

            span {
                font-size: 12px;
            }
        }
    }
}