@import "../../../assets/styles/vars.scss";

.tile-title {
  position: relative;
  margin-bottom: 25px;

  &.has-border {
    padding-bottom: 14px;
    border-bottom: 1px solid $inputBorder;
  }

  &.flex {
    display: flex;
    align-items: center;

    .ui-input-wrapper {
      width: 230px;
      margin-left: 25px;
    }

    .ui-checkbox {
      margin-left: 20px;
    }

    .ui-button {
      margin-left: 20px;
    }
  }

  &.is-centered {
    text-align: center;

    p {
      max-width: 445px;
      margin: 20px auto 0;
    }
  }

  &.is-card-title {
    margin-bottom: 32px;

    h1 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
      color: $darkfont;
      line-height: 1.45em;
    }
  }

  img {
    max-width: 410px;
    margin-top: 14px;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1em;
    color: $darkfont;

    svg {
      height: 12px;
      width: 10px;
      margin: 0 12px;

      path {
        fill: $darkfont;
      }
    }

    a {
      position: relative;
      font-weight: 500;
      color: $infoFG;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 2px;
        background: $infoFG;
      }
    }
  }

  p {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: $inputPlaceholder;
    line-height: 1.25em;

    a {
      color: #8c8b97;
      font-weight: 400;
    }
  }
}

@media (max-width: 797px) {
  .tile-title {
    img {
      max-width: 85%;
    }

    &.is-card-title {
      p {
        font-size: 15px;
      }
    }
  }
}
