.passenger {
	position: relative;
	background: #fff;
	border: 1px solid #ebebeb;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;

	&:not(:last-of-type) {
		margin-bottom: 10px;
	}

	.header {
		display: flex;
		padding: 16px 20px;
		align-items: center;
		justify-content: space-between;

		.passengerNumber {
			font-size: 11px;
			font-weight: 500;
			text-transform: uppercase;
			opacity: 0.4;
		}

		.name {
			display: flex;
			margin-top: 4px;

			p {
				font-size: 16px;
				font-weight: 500;
				margin-right: 8px;
			}
		}

		.actions {
			display: flex;
			align-items: center;
			gap: 5px;
		}
	}

	.edit {
		display: none;
		padding: 16px 20px;

		&.isShowing {
			display: block;
			border-top: 1px solid #ededed;
		}

		.fields {
			display: grid;
			grid-gap: 15px 10px;
			grid-template-columns: 1fr;
			padding-bottom: 5px;

			.title {
				padding: 0 0 2px;

				&:not(:first-child) {
					padding: 8px 0 2px;
				}

				p {
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
	}
}
