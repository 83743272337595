@import "../../../assets/styles/vars.scss";

.ui-checkbox {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  .ui-checkbox-box {
    position: relative;
    flex: 0 0 15px;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    background: #fff;
    border: 1px solid $inputBorder;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all;

    svg {
      height: 7px;
      opacity: 0;
      transition: 0.2s all;
    }

    &.is-checked {
      background: $primary;
      border: 1px solid $primary;

      svg {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  label {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    white-space: nowrap;
    color: $label;
    user-select: none;
  }
}
