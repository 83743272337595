@import "../../assets/styles/vars.scss";

.ui-table {
  &.partners-table {
    position: relative;
    width: calc(100% + 110px);
    left: -55px;

    thead {
      th {
        &:first-child {
          padding-left: 55px;
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 55px;
          }

          &.is-flexxed {
            display: flex;

            a,
            .ui-button {
              svg {
                height: 12px;

                path {
                  fill: #fff;
                }
              }
            }

            a {
              margin: 0 5px 0 4px;
            }
          }

          .partner-group-table-item {
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
            color: #303237;
            line-height: 1.5em;

            &:last-of-type {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
}

.partner-group-chosen-options {
  .partner-group-chosen-option {
    font-size: 14px;
    line-height: 1.2em;
    white-space: nowrap;
    padding-right: 12px;
    min-height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;

    &:nth-child(odd) {
      background: #f8f8f8;
    }

    &:nth-child(even) {
      border-top: 1px solid #eff2f5;
      border-bottom: 1px solid #eff2f5;
    }

    .remove-partner-group-option {
      svg {
        cursor: pointer;
        height: 12px;

        path {
          transition: 0.2s all;
        }
      }

      &:hover {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }
}
