.passenger {
	position: relative;
	background: #fff;
	border: 1px solid #ebebeb;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;
	max-width: 600px;

	&:not(:last-of-type) {
		margin-bottom: 10px;
	}

	.header {
		display: flex;
		padding: 16px 20px;
		align-items: center;
		justify-content: space-between;

		.passengerNumber {
			font-size: 11px;
			font-weight: 500;
			text-transform: uppercase;
			opacity: 0.4;
		}

		.name {
			display: flex;
			margin-top: 4px;

			p {
				font-size: 16px;
				font-weight: 500;
				margin-right: 8px;
			}
		}

		.actions {
			display: flex;
			align-items: center;
			gap: 5px;
		}
	}

	.edit {
		display: none;
		padding: 16px 20px;

		&.isShowing {
			display: block;
			border-top: 1px solid #ededed;
		}

		.fields {
			display: grid;
			grid-gap: 15px 10px;
			grid-template-columns: repeat(10, 1fr);
			padding-bottom: 5px;

			.title {
				padding: 0 0 2px;
				grid-column-end: span 10;

				&:not(:first-child) {
					padding: 8px 0 2px;
				}

				p {
					font-size: 14px;
					font-weight: 500;
				}
			}

			div {
				&:nth-child(2) {
					grid-column-end: span 2;
				}

				&:nth-child(4) {
					grid-column-end: span 2;
				}

				&:nth-child(3),
				&:nth-child(5) {
					grid-column-end: span 3;
				}

				&:nth-child(6) {
					grid-column-end: span 2;
				}

				&:nth-child(7) {
					grid-column-end: span 4;
				}

				&:nth-child(8) {
					grid-column-end: span 4;
				}

				&:nth-child(10),
				&:nth-child(11) {
					grid-column-end: span 5;
				}

				&:nth-child(13),
				&:nth-child(14),
				&:nth-child(15),
				&:nth-child(16) {
					grid-column-end: span 5;
				}
			}
		}
	}
}
