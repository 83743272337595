$ghdRed: #e41f13;
$primary: #e41f13;

$disabledBG: #e5e7e9;
$disabledFG: #8c8b97;

$inputBorder: #d1d2d4;

$label: #303237;

$inputBackground: #f4f6f8;
$inputBorder: #e5e7e9;

$inputBackgroundActive: #ffffff;
$inputBorderActive: #e41f13;

$inputPlaceholder: #8c8b97;

$defaultBG: #e7e7e7;
$defaultFG: #616161;
$positiveBG: #d3f4d5;
$positiveFG: #22b229;
$amberBG: #fff3d5;
$amberFG: #ffc021;
$negativeBG: #fcdbdb;
$negativeFG: #d3423d;
$infoBG: #d5e8ff;
$infoFG: #2a66bf;
$specialBG: #f9d5ff;
$specialFG: #b32abf;

$tileBG: #eff2f5;
$darkfont: #16171a;

$headingColor: #585858;
$headingColorLight: #b0b5bf;

$primaryFont: "Rubik", sans-serif;
