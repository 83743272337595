@import "./assets/styles/reset.scss";

* {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-style: normal;
}
