.container {
	.menu {
		padding: 5px;
		display: flex;
		align-items: center;
		background: #f7f7f7;
		border-radius: 8px;
		height: fit-content;
		margin-bottom: 18px;

		.item {
			position: relative;
			height: 26px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #fff;
			font-size: 14px;
			padding: 0 8px;
			cursor: pointer;
			transition: 200ms;
			opacity: 0.45;
			border-radius: 6px;
			user-select: none;

			&.isActive,
			&:hover {
				opacity: 1;
				box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			}

			span {
				font-size: 11px;
				background: #dedede;
				padding: 4px 6px;
				border-radius: 12px;
				margin-left: 6px;

				&.isComplete {
					background: #22b229;
					color: #fff;
				}
			}

			&:not(:last-child) {
				margin-right: 5px;
			}
		}
	}

	.tab {
		position: relative;

		.error {
			position: relative;
			background: #fff;
			border: 1px solid #ebebeb;
			border-radius: 10px;
			overflow: hidden;
			box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;
			margin-top: 15px;
			max-width: 500px;
			padding: 22px 40px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			p {
				line-height: 1.4em;
				margin: 12px 0 12px;
			}

			.paxportDetails {
				color: #435960;
				font-size: 13px;
				line-height: 1.45em;
				margin: 14px 0 0;
			}
		}
	}

	.loading {
		display: flex;
		align-items: center;

		.icon {
			position: relative;
			height: 12px;
			width: 12px;
			margin-left: 8px;
			transform: rotate(10deg);
			animation-name: loadingSpinner;
			animation-duration: 0.75s;
			animation-iteration-count: infinite;
			transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

			svg {
				position: absolute;
				top: 0;
				right: 0;
				height: 78%;
			}
		}

		p {
			font-size: 16px;
			font-weight: 400;
			color: #585858;
		}

		@keyframes loadingSpinner {
			from {
				transform: rotate(0deg);
			}

			to {
				transform: rotate(360deg);
			}
		}
	}
}

.search {
	display: flex;
	gap: 12px;
	margin-bottom: 16px;

	.input {
		width: 213px;
	}

	.maxHeight {
		max-height: 40px;

		button {
			height: 100%;
		}
	}
}

.noResults {
	p {
		position: relative;
		display: inline-block;
		font-size: 14px;
		color: #8c8b97;
		transition: 0.2s all;
		cursor: pointer;
		line-height: 1em;
	}
}
