.holiday-pricing-options {
  max-width: 705px;

  .holiday-pricing-options-flex {
    display: flex;
    align-items: center;
  }

  .holiday-pricing-search {
    max-width: 315px;
  }

  small {
    display: block;
    font-size: 12px;
    color: #878787;
    margin: 20px 0 10px;
  }

  .holiday-price-row {
    width: 100%;
    max-width: 705px;
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;

    &.is-highlighted {
      border: 1px solid #e41f13;
    }

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    .price-row-name {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      padding: 12px 0 10px 26px;

      .ui-badge {
        margin-right: 12px;
      }

      p {
        font-size: 15px;
        font-weight: 400;
      }
    }

    .price-row-details {
      height: 60px;
      display: flex;
      align-items: center;
    }

    .price-option-dates {
      display: flex;
      align-items: center;
      padding: 0 0 0 26px;
      min-width: 295px;

      p {
        font-size: 16px;
        font-weight: 500;
      }

      svg {
        margin: 0 14px;
      }
    }

    .price-option-range {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 0 0 26px;
      min-width: 155px;
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
    }

    .price-option-period {
      padding: 0 0 0 26px;
    }

    .price-option-actions {
      display: flex;
      align-items: center;
      padding: 0 16px 0 0;
      margin-left: auto;

      .price-option-action {
        height: 26px;
        width: 26px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 2px;
        }

        &:hover {
          background: #ebebeb;
        }
      }
    }
  }

  .holiday-pricing-options-missing-dates {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 22px 0;

    .holoiday-pricing-options-missing-dates-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 10px 0;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 1em;
      margin-top: 10px;

      strong {
        font-weight: 600;
      }
    }
  }

  .holiday-pricing-options-back-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 22px;

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 1em;
      margin-top: 10px;

      strong {
        font-weight: 600;
      }
    }
  }
}

.price-window {
  .price-window-room-type {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr 125px auto;
    margin-bottom: 8px;
  }

  .price-window-buggies {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 125px;
    padding-right: 38px;
  }

  .price-window-group-discount {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr 1fr auto;
    margin-bottom: 8px;
  }

  .adjusted-markup-display {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
    margin: auto 0;
    opacity: 0.65;
  }

  .price-window-target-delete {
    display: flex;
    align-self: center;
    height: 26px;
    width: 26px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 2px;
    }

    &:hover {
      background: #ebebeb;
    }
  }

  .price-window-range-figure-flex {
    display: flex;
    margin-bottom: 34px;

    .price-window-range-total {
      &:nth-child(2) {
        margin: 0 34px;
        display: flex;
        align-items: center;
      }

      .price-window-range-title {
        font-size: 14px;
        font-weight: 400;
        color: #121212;
        margin: 0 0 5px;
      }

      .price-window-range-numbers {
        .price-window-range-number {
          font-size: 22px;
          font-weight: 500;
          color: #121212;

          &:last-of-type {
            font-size: 15px;
            font-weight: 400;
            color: #585858;
            margin: 4px 0 0;
            opacity: 0.65;
          }
        }
      }
    }

    .price-window-range-figure {
      &:not(:last-of-type) {
        margin-right: 30px;
      }

      .price-window-range-figure-title {
        font-size: 18px;
        margin: 0 0 4px;
      }

      .price-window-range-figure-price {
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
}

.package-days-available-column {
  .ui-checkbox {
    margin-bottom: 5px;
  }
}