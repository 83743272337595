.page-filters {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: 250px 140px 110px 150px;
	margin-bottom: 25px;

	&.extra {
		grid-template-columns: 250px 140px 110px 140px 150px;
	}

	&.extra-stats {
		grid-template-columns: 250px 140px 110px 140px 130px 1fr;
	}

	&.extra-alt {
		grid-template-columns: 150px 140px 110px 150px;

		&.added-type {
			grid-template-columns: 150px 220px 140px 110px 150px;
		}
	}

	&.flights {
		grid-template-columns: 150px 125px 165px 150px;
	}
}

.ui-table {
	&.travellers-table {
		position: relative;
		width: calc(100% + 110px);
		left: -55px;

		thead {
			th {
				&:first-child {
					padding-left: 55px;
				}
			}
		}

		tbody {
			tr {
				td {
					&:first-child {
						padding-left: 55px;
					}
				}

				&.totals-row {
					td {
						font-weight: 500;
					}
				}
			}
		}
	}
}
