.tracking-table {
	td {
		font-size: 14px;
		white-space: nowrap;
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.tracking-stats {
	color: #666;
	font-size: 14px;
	margin: 8px 0 0;
}

.no-value {
	color: #999;
}

.tracking-chart {
	margin: 0 0 30px;
	background: #fff;
	border-radius: 8px;
}
