#error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .error-text {
    h1 {
      font-size: 182px;
      font-weight: 800;
      margin: 0;
      text-align: center;
      line-height: 1em;
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
      margin: 2px 0 0;
      text-align: center;
      line-height: 1em;
    }
  }
}
