$calendar-background-color: #fff;
$calendar-header-color: #333;
$calendar-header-background-color: #eee;
$calendar-day-color: #333;
$calendar-day-background-color: #f5f5f5;

.calendar-ui-wrapper {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;

    button {
      border: none;
      font-size: 1.2rem;
      height: 30px;
      width: 30px;
      border-radius: 4px;
      background: #e41f13;
      cursor: pointer;
      transition: 0.2s all;

      &:hover {
        text-decoration: underline;
        background: darken(#e41f13, 5%);
      }

      &:nth-child(2) {
        margin-left: 6px;
      }

      &:nth-child(4) {
        margin-right: 6px;
      }
    }

    p {
      margin: 0 auto;
      font-size: 18px;
    }
  }

  .days-of-week {
    display: flex;
    border-bottom: 1px solid #eff2f5;

    div {
      flex: 1;
      text-align: center;
      padding: 10px 10px 5px;
      font-size: 14px;
      font-weight: 400;
      color: #585858;
      margin: 4px 0 0;
      opacity: 0.65;
    }
  }

  .body {
    display: flex;
    flex-wrap: wrap;

    .day {
      flex-basis: calc(100% / 7);
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid transparent;
      background: #fff;
      position: relative;
      border: 2px solid transparent;

      &.other {
        color: #ccc;
      }

      &:not(.other) {
        &:hover {
          color: #fff;
          cursor: pointer;
          background: #e41f13;
        }
      }

      &.is-active {
        color: #fff;
        background: #e41f13 !important;
      }

      &.is-chosen {
          border-radius: unset;
          border-top: 2px solid #e41f13;
          border-bottom: 2px solid #e41f13;
          
          &.is-chosen-first {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            border-left: 2px solid #e41f13;
          }
          
          &.is-chosen-last {
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            border-right: 2px solid #e41f13;
          }
      }

      &.is-confirmed {
          color: #fff;
          background: #e41f13;

          &.is-confirmed-first {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
          }

          &.is-confirmed-last {
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
          }
       }

      .today-indicator {
        position: absolute;
        top: 2px;
        right: 2px;
        height: 7px;
        width: 7px;
        border-radius: 100%;
        background: #e41f13;
      }

      .date {
        font-size: 16px;
        text-align: center;
      }

      .price {
        font-size: 14px;
        opacity: 0.65;
        margin: 3px 0 0;
      }
    }
  }
}
