.loading-spinner {
  display: flex;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #585858;
  }

  .loading-svg-wrap {
    position: relative;
    height: 12px;
    width: 12px;
    margin-left: 8px;
    transform: rotate(10deg);
    animation-name: loadingSpinner;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

    svg {
      position: absolute;
      top: 0;
      right: 0;
      height: 78%;
    }
  }

  @keyframes loadingSpinner {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
