@import "../../assets/styles/vars.scss";

.system-users-table {
  position: relative;
  width: calc(100% + 110px);
  left: -55px;

  thead {
    th {
      &:first-child {
        width: 325px;
        padding-left: 55px;
      }

      &:nth-child(2) {
        width: 375px;
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          width: 325px;
          padding-left: 55px;
        }

        &:nth-child(2) {
          width: 375px;
        }

        .ui-checkbox {
          height: 30px;

          &:last-child {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
