@import "../../../assets/styles/vars.scss";

.ui-window-block {
	&.has-top-margin {
		margin-top: 20px;
	}

	&.is-stacked {
		padding-bottom: 24px;
		border-bottom: 1px solid $tileBG;
		margin-bottom: 24px;
	}

	.ui-window-card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 14px;

		.ui-window-card-title {
			font-size: 15px;
			font-weight: 400;
			color: #121212;
			margin: 0;
		}

		.ui-window-card-subtitle {
			font-size: 14px;
			font-weight: 400;
			color: $headingColor;
			margin: 4px 0 0;
			opacity: 0.65;
		}

		.ui-button {
			&:not(:last-child) {
				margin-right: 5px;
			}
		}
	}

	.input-grid {
		display: grid;
		grid-gap: 12px;

		&.cols-2 {
			grid-template-columns: repeat(2, 1fr);
		}

		&.cols-3 {
			grid-template-columns: repeat(3, 1fr);
		}

		&.cols-4 {
			grid-template-columns: repeat(4, 1fr);
		}

		.span-2 {
			grid-column-end: span 2;
		}

		.span-4 {
			grid-column-end: span 4;
		}
	}
}

.window-card-button {
	margin-top: 25px;
}

.vouchers-loading-wrapper {
	display: flex;
	align-items: center;

	p {
		opacity: 0.65;
		font-size: 14px;
		font-weight: 400;
		color: $headingColor;
	}

	.svg-loading-wrap {
		position: relative;
		height: 10px;
		width: 10px;
		margin-left: 8px;
		transform: rotate(10deg);
		animation-name: loadingSpinner;
		animation-duration: 0.75s;
		animation-iteration-count: infinite;
		transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

		svg {
			position: absolute;
			top: 0;
			right: 0;
			height: 78%;
		}
	}

	@keyframes loadingSpinner {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}
}

.gift-vouchers {
	position: relative;

	.voucher {
		display: flex;
		align-items: center;
		background: #fff;
		border: 1px solid #ebebeb;
		border-radius: 6px;
		box-shadow: 0 0 24px #0000000f;
		height: 54px;
		overflow: hidden;
		padding: 2px;
		width: 100%;
		cursor: pointer;

		&.not-available {
			opacity: 0.4;
			cursor: default;
			pointer-events: none;
		}

		img {
			border-radius: 4px;
			height: 100%;
		}

		.voucher-details {
			padding-left: 12px;

			p {
				font-size: 14px;
				font-weight: 500;
				margin-bottom: 4px;
			}

			span {
				display: block;
			}
		}
	}
}

.no-vouchers {
	font-size: 14px;
}
