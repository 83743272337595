@import "../../assets/styles/vars.scss";

.staff-targets-table {
  position: relative;
  width: calc(100% + 110px);
  left: -55px;

  thead {
    th {
      &:first-child {
        width: 325px;
        padding-left: 55px;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 180px;
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          width: 325px;
          padding-left: 55px;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: 180px;
        }

        &:nth-child(2) {
          .ui-input-wrapper {
            .ui-input-block {
              .ui-input-symbol {
                background: $primary;
                color: #fff;
              }
            }
          }
        }

        .ui-input-wrapper {
          width: 140px;
          height: 35px;

          &.has-symbol {
            .ui-input-block {
              input {
                padding: 5px 5px 5px 38px !important;
              }
            }
          }

          .ui-input-block {
            height: 35px;

            .ui-input-symbol {
              top: 6px;
              bottom: 0;
              border-radius: 4px 0 0 4px;
            }

            input {
              padding: 5px 5px 5px 50px !important;
            }
          }
        }
      }
    }
  }
}
