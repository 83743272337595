@import "../../../assets/styles/vars.scss";

.no-payments-notice {
  font-size: 15px;
  line-height: 1.45em;
  color: $headingColorLight;
}

.booking-payments-height {
  max-height: 400px;
  overflow-y: auto;
}

.booking-payments {
  width: 100%;

  tbody {
    tr {
      text-align: left;

      &:nth-child(odd) {
        background: #f8f8f8;
      }

      &:nth-child(even) {
        border-top: 1px solid #eff2f5;
        border-bottom: 1px solid #eff2f5;
      }

      td {
        font-size: 15px;
        line-height: 42px;
        white-space: nowrap;
        padding-right: 12px;

        &:first-child {
          padding-left: 12px;
        }

        small {
          color: $headingColorLight;
        }
      }
    }
  }
}
