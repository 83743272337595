@import "../../../assets/styles/vars.scss";

.ui-input-wrapper {
  position: relative;

  &.has-error {
    .ui-input-block {
      .ui-input-text {
        border: 1px solid red;
      }
    }

    small {
      color: red;
    }
  }

  .ui-input-block {
    position: relative;

    .ui-input-icon {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);

      svg {
        height: 15px;
        pointer-events: none;

        path {
          fill: $inputPlaceholder;
        }
      }
    }

    .ui-input-upper-placeholder {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      font-size: 14px;
      font-weight: 500;
      color: $inputPlaceholder;
      transition: 0.25s all;
      opacity: 0;
      padding: 3px 6px;

      &.active {
        top: 0px;
        left: 14px;
        opacity: 1;
        font-size: 12px;
        background: #fff;
      }
    }

    .ui-input-file {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    .ui-input-fakefile {
      font-size: 15px;
      background: $inputBackground;
      border: 1px solid $inputBorder;
      transition: 0.2s all;
      border-radius: 5px;
      padding: 10px 18px 10px 40px;
      width: 100%;
      outline: none !important;
      color: $label;
      font-family: "Rubik", sans-serif;
      resize: none;
      cursor: pointer;

      p {
        min-height: 18px;
        font-family: "Rubik", sans-serif;
        line-height: 18px;
        margin: 0;

        &.is-placeholder {
          font-size: 14px;
          font-weight: 400;
          color: $inputPlaceholder;
        }

        &.is-value {
          font-size: 15px;
          font-weight: 400;
          color: $label;
        }
      }

      &:hover,
      &:focus,
      &:active {
        background: $inputBackgroundActive;
        border: 1px solid $inputBorderActive;
      }
    }
  }

  small {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 1em;
    margin: 5px 0 0;
    color: $inputPlaceholder;
  }
}
