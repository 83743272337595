@import "../../../assets/styles/vars.scss";

.ui-button {
  position: relative;
  background: $primary;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: 0.2s all;
  font-family: $primaryFont;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  padding: 10px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    background: darken($primary, 5%);
  }

  &.secondary {
    color: #585858;
    background: #f5f2f2;

    &:hover {
      background: darken(#f5f2f2, 5%);
    }
  }

  &.SUCCESS,
  &.POSITIVE {
    background: $positiveFG;

    &:hover {
      background: darken($positiveFG, 5%);
    }
  }

  &.ALERT,
  &.AMBER {
    background: $amberFG;

    &:hover {
      background: darken($amberFG, 5%);
    }
  }

  &.ERROR,
  &.NEGATIVE {
    background: $negativeFG;

    &:hover {
      background: darken($negativeFG, 5%);
    }
  }

  &.OUTLINE {
    color: $disabledFG;
    background: #fff;
    border: 1px solid $disabledFG;

    &:hover {
      background: $inputBackground;
    }
  }

  &.is-loading {
    pointer-events: none;
    background: $disabledBG !important;
    color: $disabledFG !important;
    border: none !important;
  }

  &.is-full-width {
    width: 100%;
  }

  &.is-small {
    padding: 10px 14px;
    font-size: 12px;
    line-height: 1em;
  }

  &.is-extra-small {
    padding: 8px 14px;
    font-size: 12px;
    line-height: 1em;

    .ui-button-icon {
      width: 9px;
      margin-top: -3px;

      svg {
        width: 9px;
        display: block;
      }
    }
  }

  &.has-extra-margin {
    margin: 0 0 25px;
  }

  &.is-filters {
    font-size: 13px;
    padding: 12px 28px;
  }

  &.is-full-width {
    width: 100%;
  }

  &.is-badge {
    flex-direction: row-reverse;
    font-size: 13px;
    padding: 6px 12px;

    &.is-loading {
      flex-direction: row;
    }

    .ui-button-icon {
      margin-left: 0;
      margin-right: 10px;
      height: 10px;
      width: 10px;
    }
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: default;
  }

  .ui-button-icon {
    position: relative;
    height: 12px;
    width: 12px;
    margin-left: 8px;

    &.no-label {
      margin-left: 0;
    }

    svg {
      position: absolute;
      height: 110%;
      top: 0;
      left: 0;

      path {
        fill: #fff;
      }
    }
  }

  .ui-button-loading-icon {
    position: relative;
    height: 12px;
    width: 12px;
    margin-left: 8px;
    transform: rotate(10deg);
    animation-name: loadingSpinner;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

    svg {
      position: absolute;
      top: 0;
      right: 0;
      height: 78%;
    }
  }

  &.no-loading-margin {
    .ui-button-loading-icon {
      margin-left: unset;
    }
  }
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
