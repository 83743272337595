@import "../../../assets/styles/vars.scss";

.ui-modal-box {
  position: relative;
  width: 340px;
  background: #fff;
  border-top: 4px solid $infoFG;
  pointer-events: all;
  border-radius: 4px;
  padding: 36px 10px 20px;

  &.SUCCESS {
    border-top: 4px solid $positiveFG;

    .ui-modal-icon {
      background: $positiveFG;
    }
  }

  &.ALERT {
    border-top: 4px solid $amberFG;

    .ui-modal-icon {
      background: $amberFG;
    }
  }

  &.ERROR {
    border-top: 4px solid $negativeFG;

    .ui-modal-icon {
      background: $negativeFG;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .ui-modal-icon {
    position: absolute;
    z-index: 2;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background: $infoFG;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      max-height: 18px;
      width: 100%;
      max-width: 14px;
      transform: translate(-50%, -50%);
    }
  }

  .ui-modal-title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.25em;
    margin: 0;
  }

  .ui-modal-body {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4em;
    color: $inputPlaceholder;
    margin: 10px auto 0;
    max-width: 265px;
  }

  .ui-modal-buttons {
    position: relative;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
