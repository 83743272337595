 .resort-note-row {
    width: 100%;
    max-width: 705px;
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    .price-row-name {
      display: flex;
      align-items: center;
      border-top: 1px solid #ebebeb;
      padding: 12px 0 12px 20px;
      
      &.bottom-border {
        border-top: unset;
        border-bottom: 1px solid #ebebeb;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.4em;
      }
    }

    .price-row-details {
      height: 38px;
      display: flex;
      align-items: center;
    }

    .price-option-dates {
      display: flex;
      align-items: center;
      padding: 0 0 0 18px;
      min-width: 265px;

      p {
        font-size: 14px;
        font-weight: 500;
      }

      svg {
        margin: -2px 9px 0;
      }
    }

    .price-option-range {
      display: flex;
      align-items: center;
      height: 100%;
      min-width: 155px;
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
    }

    .price-option-period {
      padding: 0 0 0 20px;
      margin-top: -3px;
    }

    .price-option-actions {
      display: flex;
      align-items: center;
      padding: 0 16px 0 0;
      margin-left: auto;

      .price-option-action {
        height: 26px;
        width: 26px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 2px;
        }

        &:hover {
          background: #ebebeb;
        }
      }
    }
  }
