@import "../../../assets/styles/vars.scss";

.upload-flight-details {
  .upload-error {
    display: block;
    color: $primary;
    font-size: 13px;
    font-weight: 400;
    margin-top: 14px;
  }
}
