@import "../../assets/styles/vars.scss";

#itinerary-page {
  position: relative;
  background-position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 20px;
  min-height: 100vh;

  .screen-tile {
    position: relative;
    z-index: 5;
    width: 95%;
    max-width: 710px;
  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    height: 100%;
    width: 100vw;
    overflow: hidden;

    .background-video {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .countdown {
    display: block;
    font-size: 22px;
    text-align: center;
    font-weight: 600;

    span {
      color: $primary;
    }

    &.small {
      font-size: 16px;
      font-weight: 400;
      margin-top: 24px;
    }
  }

  .payment-details {
    margin: 0 auto 20px;

    tr {
      td {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.35em;

        &:last-child {
          color: $primary;
          padding-left: 8px;
        }
      }
    }
  }

  .financial-protection {
    text-align: center;
    font-size: 15px;
    line-height: 1.35em;
  }

  .atol-protection-logo {
    margin: 15px auto 0;
    width: 125px;
    align-self: center;
    display: flex;
  }

  .no-payments-notice {
    display: block;
    text-align: center;
    max-width: 500px !important;
    margin: 0 auto;
  }

  .booking-payments-cover {
    overflow-y: auto;
    max-height: 200px;
  }

  .booking-payments {
    thead {
      th {
        text-align: left;
        color: $headingColor;
        font-size: 14px;
        line-height: 1em;
        padding-bottom: 8px;
        padding-right: 12px;
        white-space: nowrap;

        &:first-child {
          padding-left: 12px;
        }
      }
    }
  }

  .my-booking-navigation {
    position: relative;
    display: grid;
    grid-gap: 10px;
    margin: 38px auto -30px;
    width: calc(100% + 110px);
    grid-template-columns: 310px 1fr;
    justify-content: center;
    left: -55px;

    .mb-nav-list {
      .mb-nav-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.2s all;
        background: #fff;
        cursor: pointer;
        min-height: 48px;
        padding: 0 20px 0 35px;
        color: #fff;

        &.tall {
          min-height: 64px;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }

        &:nth-child(odd) {
          background: #f8f8f8;
        }

        &:nth-child(even) {
          border-top: 1px solid #eff2f5;
          border-bottom: 1px solid #eff2f5;
        }

        &:hover {
          background: $primary;

          p {
            color: #fff;
          }

          small {
            color: #fff;
          }

          svg {
            path {
              fill: #fff;
            }
          }
        }

        p {
          color: $darkfont;
          font-size: 16px;
          font-weight: 500;
          transition: 0.2s all;
        }

        small {
          display: block;
          font-size: 14px;
          font-weight: 500;
          transition: 0.2s all;
          color: $primary;
          margin-top: 6px;
        }

        svg {
          height: 15px;
          margin-top: -3px;

          path {
            fill: $primary;
            transition: 0.2s all;
          }
        }
      }
    }

    .mb-nav-courses {
      .carousel-root,
      .carousel,
      .slider-wrapper,
      .slider {
        height: 100%;
      }
    }
  }
}

.flight-details-image-thumb {
  display: inline-block;
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 4px;
  border: 1px solid $inputBorder;
  cursor: pointer;
  transition: 0.2s;
  margin-right: 10px;

  &:hover {
    transform: scale(1.1);
  }

  span {
    position: absolute;
    z-index: 2;
    height: 17px;
    width: 17px;
    top: -8px;
    right: -8px;
    border-radius: 100%;
    background: #fff;
    border: 1px solid $inputBorder;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    svg {
      height: 6px;

      path {
        fill: $primary;
        transition: 0.2s;
      }
    }

    &:hover {
      background: $primary;
      border: 1px solid $primary;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  img {
    display: block;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.nearby-places-slider {
  position: relative;
  width: calc(100% + 90px);
  left: -45px;
  margin-bottom: -20px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;

  &.with-margin {
    margin-top: 30px;
  }
}

.check-with-agent {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.35em;
}

.nearby-place {
  position: relative;
  height: 335px;
  background: red;

  &.small-height {
    height: 100%;
    min-height: 256px;
  }

  img {
    display: block !important;
    position: absolute;
    z-index: 2;
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
    object-position: center;
  }

  .nb-title {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    padding: 40px 30px 25px;
    text-align: left;

    p {
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      line-height: 1.25em;
      margin-bottom: 2px;
      letter-spacing: -0.5px;
    }

    span {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      line-height: 1.25em;
      letter-spacing: -0.5px;
      opacity: 0.75;
    }

    a {
      position: relative;
      margin-top: 14px;
      background: $primary;
      border: none;
      color: #fff;
      cursor: pointer;
      transition: 0.2s all;
      font-family: $primaryFont;
      font-size: 13px;
      font-weight: 400;
      border-radius: 4px;
      padding: 10px 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      width: fit-content;

      &:hover {
        background: darken($primary, 5%);
      }
    }
  }
}

@media (max-width: 480px) {
  #itinerary-page {
    padding: 20px;

    .screen-tile {
      padding: 25px 20px 20px;

      .tile-title {
        margin-bottom: 15px;

        h1 {
          font-size: 20px;
          text-align: center;
        }
      }
    }

    .ui-horizontal-nav {
      .ui-horizontal-nav-link {
        font-size: 12px;

        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }

    table {
      width: 100%;

      &.payment-details {
        tr {
          &:not(:last-child) {
            border-bottom: 2px solid #fff;
          }
        }
      }

      &.booking-payments {
        &.check-ins {
          tr {
            td {
              &:nth-child(2) {
                width: auto;
              }
            }
          }
        }
      }

      tr {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          border-bottom: 12px solid #fff;
        }

        td {
          border-right: 0px solid #fff;
          border-bottom: 0px solid #fff;

          &:first-child {
            font-size: 14px;
          }

          &:nth-child(2) {
            width: 100%;
          }

          .ui-button {
            margin-top: 10px;
          }

          .ui-input-wrapper {
            width: 100%;
          }

          .table-data-split {
            &.cols-2 {
              grid-template-columns: 1fr;
            }
          }
        }
      }
    }
  }

  .ui-window-page {
    .ui-window-card {
      &.slim {
        width: 90vw;
      }

      .ui-window-title {
        h1 {
          font-size: 18px !important;
        }
      }
    }
  }

  .no-payments-notice {
    font-size: 14px !important;
  }
}

@media (max-width: 797px) {
  #itinerary-page {
    padding: 15px;

    .countdown {
      font-size: 18px;
      margin: 30px 0 0;
    }

    .my-booking-navigation {
      position: relative;
      left: -15px;
      width: calc(100% + 30px);
      grid-template-columns: 1fr;
      margin-bottom: -20px;

      .mb-nav-courses {
        height: 220px;
      }
    }
  }

  .booking-payments-cover {
    max-height: 220px !important;
  }

  .booking-payments,
  .payment-details {
    margin: 15px 0 20px !important;

    thead,
    tbody {
      tr {
        display: table-row !important;
        flex-direction: unset !important;

        td {
          font-size: 14px !important;
          white-space: nowrap !important;
        }
      }
    }
  }

  .nearby-places-slider {
    width: calc(100% + 20px);
    left: -10px;
    margin-bottom: -14px;
  }

  .nearby-place {
    img {
      display: block !important;
      position: absolute;
      z-index: 2;
      height: 100% !important;
      width: 100% !important;
      object-fit: cover;
      object-position: center;
    }

    .nb-title {
      padding: 20px 45px 40px 20px;

      p {
        font-size: 18px;
      }
    }
  }
}
