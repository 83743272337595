@import "../../../assets/styles/vars.scss";

.ui-badge {
  position: relative;
  font-size: 11px;
  font-weight: 500;
  background: $defaultBG;
  color: $defaultFG;
  border-radius: 3px;
  padding: 4px 9px;

  &.POSITIVE {
    background: $positiveBG;
    color: $positiveFG;
  }

  &.AMBER {
    background: $amberBG;
    color: $amberFG;
  }

  &.NEGATIVE {
    background: $negativeBG;
    color: $negativeFG;
  }

  &.INFO {
    background: $infoBG;
    color: $infoFG;
  }

  &.SPECIAL {
    background: $specialBG;
    color: $specialFG;
  }
}
