.app-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3005;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  .app-modal-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.55);
    animation-name: fadeIn;
    animation-duration: 0.15s;
  }
}

@keyframes fadeIn {
  from {
    background: rgba(0, 0, 0, 0);
  }

  to {
    background: rgba(0, 0, 0, 0.4);
  }
}
