@import "../../assets/styles/vars.scss";

.include-button-styles {
  &.disable-button {
    a {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  a {
    position: relative;
    background: $primary;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: 0.2s all;
    font-family: $primaryFont;
    font-weight: 400;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 10px 14px;
    font-size: 12px;
    line-height: 1em;
    width: fit-content;

    &:hover {
      background: darken($primary, 5%);
    }
  }
}
