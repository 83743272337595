.user-picture-flex {
    display: flex;
    align-items: center;

    .ui-input-wrapper {
        flex: 1 1;
    }
}

.user-profile-picture {
    flex: 0 0 40px;
    display: block;
    position: relative;
    height: 40px;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 3px;
    margin-right: 8px;

    &:hover {
        img {
            transform: scale(1.05);
        }
    }

    img {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        transition: 0.2s all;
        transform-origin: center;
    }
}

.user-action-buttons {
    .ui-button {
        &:not(:last-of-type) {
            margin-bottom: 4px;
        }
    }
}