@import "../../../assets/styles/vars.scss";

.ui-window-page {
  position: fixed;
  z-index: 2005;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  padding: 9vh 0;
  overflow: auto;

  .ui-window-card {
    background: #fff;
    height: fit-content;
    border-radius: 10px;
    width: 100%;
    max-width: 720px;
    padding: 25px 35px 35px;

    &.slim {
      max-width: 480px;
    }

    .ui-window-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
      border-bottom: 2px solid $tileBG;
      margin-bottom: 15px;

      h1 {
        font-size: 22px;
        line-height: 1em;
        font-weight: 500;
      }

      small {
        display: block;
        font-size: 14px;
        line-height: 1em;
        font-weight: 400;
        margin-top: 5px;
        color: $headingColorLight;
      }
    }

    .ui-window-actions {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.alt-layout {
        flex-direction: row-reverse;
      }
    }
  }
}
