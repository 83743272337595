.screen-tile {
  .tile-title {
    &.has-select-field {
      .ui-select-wrapper {
        width: 230px;
        margin-left: 25px;

        p {
          margin-top: 0;
          font-size: 14px;
        }

        .ui-select-upper {
          min-height: 34px;

          &:not(.no-styles) {
            padding: 7px 18px 6px;
          }
        }
      }

      .ui-input-wrapper {
        margin-left: 10px;
      }
    }
  }
}
