.cabin {
	background: #fff;
	padding: 85px 62px 45px;

	.row {
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;
		width: 100%;

		.group {
			flex: 1 1;
			display: flex;
			gap: 8px;

			&:first-child {
				justify-content: flex-end;
			}
		}

		.number {
			flex: 0 0 45px;
			font-size: 12px;
			opacity: 0.4;
			width: 45px;
			text-align: center;
			user-select: none;
		}

		.seat {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			cursor: pointer;

			&.isUnavailable {
				cursor: default;
				opacity: 0.25;
				pointer-events: none;
			}

			&.inBasket {
				svg {
					width: 28px;

					path,
					rect {
						fill: red !important;
					}
				}
			}

			svg {
				width: 28px;

				path,
				rect {
					fill: #000 !important;
				}
			}
		}
	}
}
