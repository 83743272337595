.flight-row {
	position: relative;
	background: #fff;
	border: 1px solid #ebebeb;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;
	min-width: 415px;
	max-width: 575px;
	width: 100%;

	&.is-lite {
		margin-top: 10px;
	}

	&:not(:last-of-type) {
		margin-bottom: 10px;
	}

	.flight-row__splitter {
		margin: 0 20px;
		border-bottom: 1px dotted #a3aeb2;
	}

	.flight-row__header {
		padding: 22px 20px;

		&:last-of-type {
			margin-bottom: 20px;
		}

		.flight-row__header--number {
			display: flex;
			align-items: center;
			color: #435960;
			font-size: 13px;
			margin-bottom: 18px;

			img {
				height: 13px;
				margin-right: 10px;
			}
		}

		.flight-row__header--flight {
			display: flex;
			gap: 15px;

			.flight-row__header--departure {
				text-align: left;
			}

			.flight-row__header--departure,
			.flight-row__header--arrival {
				flex: 0 0 fit-content;

				.flight-row__header--time {
					color: #01010a;
					font-size: 21px;
					font-weight: 400;
				}

				.flight-row__header--date {
					color: #435960;
					font-size: 13px;
					margin-top: 6px;
				}
			}

			.flight-row__header--arrival {
				text-align: right;
			}

			.flight-row__header--journey {
				position: relative;
				flex: 1 1;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.flight-row__header--line {
					position: relative;
					z-index: 1;
					display: block;
					height: 1px;
					width: 100%;
					border-bottom: 1px dotted #a3aeb2;
					// background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

					&.is-return {
						svg {
							transform: translate(-50%, -50%) rotate(180deg);
						}
					}

					svg {
						display: block;
						position: absolute;
						z-index: 2;
						height: 10px;
						left: 50%;
						transform: translate(-50%, -50%);
					}

					&::before {
						content: "";
						position: absolute;
						display: block;
						height: 6px;
						width: 6px;
						top: -3px;
						left: 0;
						background: #fff;
						border: 1px solid #a3aeb2;
						border-radius: 50%;
					}

					&::after {
						content: "";
						position: absolute;
						display: block;
						height: 6px;
						width: 6px;
						top: -3px;
						right: 0;
						background: #fff;
						border: 1px solid #a3aeb2;
						border-radius: 50%;
					}
				}

				.flight-row__header--duration {
					color: #a3aeb2;
					font-size: 13px;
					margin-top: 9px;
				}
			}
		}
	}

	.flight-row__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 17px 20px;
		background: #f7f7f7;

		.flight-row__footer--price {
			p {
				font-size: 22px;
				font-weight: 600;
			}

			span {
				font-size: 13px;
				color: #a3aeb2;
			}
		}
	}
}
