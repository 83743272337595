.ui-table {
  &.bookings-table {
    position: relative;
    width: calc(100% + 110px);
    left: -55px;

    thead {
      th {
        &:first-child {
          padding-left: 55px;
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 55px;
          }
        }
      }
    }
  }
}
