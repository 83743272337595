@import "../../assets/styles/vars.scss";

.leaderboard-background {
  padding: 20px;
  background: #eff2f5;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .leaderboard-tile {
    position: relative;
    background: #fff;
    padding: 30px 0;
    border-radius: 18px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    img {
      max-width: 480px;
      margin: 0 0 35px;
    }

    .ui-table {
      &.leaderboard-table {
        position: relative;
        width: 100%;

        thead {
          th {
            padding-left: 22px;
            padding-right: 30px;
            font-size: 16px;

            &:first-child {
              padding-left: 65px;
            }

            &:last-child {
              padding-right: 65px;
            }
          }
        }

        tbody {
          tr {
            &.leaderboard-totals {
              td {
                p {
                  font-weight: 600;
                }
              }
            }

            td {
              line-height: 55px;
              padding-right: 20px;

              &:first-child {
                padding-left: 65px;

                p {
                  padding: 0;
                }
              }

              &:last-child {
                padding-right: 65px;
              }

              p {
                font-size: 22px;
                padding-left: 20px;
                border-radius: 5px;

                small {
                  font-size: 16px;
                  margin-left: 6px;
                }

                &.is-complete {
                  line-height: 42px;
                  color: $positiveFG;
                  background: $positiveBG;

                  small {
                    opacity: 0.5;
                    color: $positiveFG;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 2000px) {
  .leaderboard-background {
    .leaderboard-tile {
      .ui-table {
        &.leaderboard-table {
          thead {
            th {
              font-size: 24px;
            }
          }

          tbody {
            tr {
              td {
                line-height: 78px;

                p {
                  font-size: 38px;

                  small {
                    font-size: 30px;
                  }

                  &.is-complete {
                    line-height: 64px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 979px) {
  .leaderboard-background {
    padding: 10px;

    .leaderboard-tile {
      padding: 35px 10px;
      align-items: unset;

      img {
        position: fixed;
        max-width: 265px;
        margin: 0 auto 35px;
        left: 50%;
        transform: translateX(-50%);
      }

      .ui-table {
        overflow: auto;
        margin-top: 65px;
        margin-left: -12px;
        margin-right: -10px;

        &.leaderboard-table {
          thead {
            th {
              padding-left: 10px;
              padding-right: 20px !important;
              font-size: 12px;

              &:first-child {
                padding-left: 20px;
              }
            }
          }

          tbody {
            tr {
              td {
                line-height: 38px;
                padding-right: 20px !important;

                &:first-child {
                  padding-left: 20px;

                  p {
                    padding: 0;
                  }
                }

                p {
                  font-size: 14px;
                  padding-left: 10px;

                  small {
                    font-size: 12px;
                    margin-left: 6px;
                  }

                  &.is-complete {
                    line-height: 34px;
                    padding-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 979px) and (orientation: landscape) {
  .leaderboard-background {
    padding: 10px;

    .leaderboard-tile {
      padding: 35px 10px;

      img {
        max-width: 280px;
        margin: 0 auto 35px;
      }

      .ui-table {
        &.leaderboard-table {
          thead {
            th {
              padding-left: 10px;
              padding-right: 20px !important;
              font-size: 12px;

              &:first-child {
                padding-left: 20px;
              }
            }
          }

          tbody {
            tr {
              td {
                line-height: 38px;
                padding-right: 20px !important;

                &:first-child {
                  padding-left: 20px;

                  p {
                    padding: 0;
                  }
                }

                p {
                  font-size: 14px;
                  padding-left: 10px;

                  small {
                    font-size: 12px;
                    margin-left: 6px;
                  }

                  &.is-complete {
                    line-height: 34px;
                    padding-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
