.quoting-panel {
    padding-bottom: 100px;
}

.package-basket-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #eff2f5;
    margin-left: 295px;
    border-top: 2px solid #f1f1f1;
    
    .package-basket-inner {
        padding: 12px 26px;
        background: #fff;
        text-align: center;

        p {
            font-size: 15px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);

            span {
                color: #e41f13;;
                font-weight: 600;
            }
        }
    }
}